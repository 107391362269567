<template>
  <!-- 配置图片 -->

  <el-form-item :label="ConfigModel.Name">
    <el-upload
      class="avatar-uploader"
      :show-file-list="false"
      :before-upload="beforeAvatarUpload"
      :headers="headersImage"
      :action="UploadImageUrl"
      :on-success="UploadPictureSuccess"
    >
      <img v-if="ImageValue" :src="ImageValue" class="avatar" />
      <el-icon v-else class="avatar-uploader-icon"><plus /></el-icon>
    </el-upload>
  </el-form-item>
</template>
<script>
import { ElMessage } from "element-plus"
import { Plus } from "@element-plus/icons-vue"
import axios from "../../src/commons/AxiosMethod.js"
export default {
  name: "header",
  data () {
    return {
      ImageValue: "",
      UploadImageUrl: "",

      ConfigModel: {}
    }
  },
  props: {
    Key: ""
  },
  components: {
    Plus
  },
  mounted () {
    this.GetConfig(this.Key)
    this.UploadImageUrl = axios.GetUrl() + "/api/Upload/ImageFile"
    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header")
    }

    // 这个是页面创建的时候，就执行相关的方法
  },
  methods: {
    GetConfig (key) {
      axios.apiMethod(
        "/admins/SystemConfiguration/GetConfigurationsByKey",
        "get",
        { Key: key },
        (result) => {
          if (result.Code == 200) {
            this.ConfigModel = result.Data
            this.ImageValue = this.ConfigModel.ValueFormat
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },

    // 图片上传成功操作
    UploadPictureSuccess (response, file, fileList) {
      if (response.Code == 200) {
        this.ConfigModel.Value = response.Data.FileName
        this.ImageValue = response.Data.ImageFormet

        axios.apiMethod(
          "/admins/SystemConfiguration/AddOrUpdateConfigurations",
          "post",
          this.ConfigModel,
          (result) => {
            if (result.Code == 200) {
              ElMessage({
                message: "上传成功",
                type: "success"
              })
            } else {
              ElMessageBox({
                message: result.FullMsg,
                type: "error"
              })
            }
          }
        )
      }
    }
  }
}
</script>
<style lang="less" scoped>
.avatar-uploader,
.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader,
.el-upload:hover {
  border-color: #409eff;
}
.el-icon,
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
