<template>
  <navigation index="sysManages" ref="navigation">
    <div class="topnavigation"> 

      <el-button @click="DeleteAllCache()">删除缓存</el-button>
     
    </div>
  <el-tabs
    v-model="ConfigurationActiveName"
    class="demo-tabs"
    @tab-click="handleClick"
  >
    <!-- <el-tab-pane name="first" label="主机设置">
      <el-button @click="UpdateWebHostConfiguration()">更新主机配置</el-button>
      <el-button @click="CreateDocument()">创建默认数据</el-button>
      <el-button @click="WriteInDocument()">写入默认数据</el-button>

    </el-tab-pane> -->
    
    <el-tab-pane name="first" label="邮箱配置">
      <el-form label-width="150px" class="configModule">
        <ConfigurationTextModule Key="system.mail" />
        <ConfigurationTextModule Key="system.mail.accounts" />
        <ConfigurationTextModule Key="system.mail.password" />
        <ConfigurationTextModule Key="system.mail.host" />
        <ConfigurationTextModule Key="system.mail.port" />
      </el-form>
    </el-tab-pane>
    <el-tab-pane name="all" label="全部">
      <el-button size="small" @click="ConfigurationAdd()">新增</el-button>

      <el-table
        border
        :data="Configurations"
        style="width: 100%"
        height="600px"
      >
        <el-table-column label="主键" prop="Key" />
        <el-table-column label="名称" prop="Name" />
        <el-table-column label="值" prop="Value" />
        <el-table-column label="类型" prop="ConfigureTypeFormet" />
        <el-table-column align="right">
          <template #header>
            <el-input v-model="SearchConfigValue" placeholder="Please input">
              <template #append>
                <el-button
                  type="primary"
                  @click="GetConfigurations"
                  :icon="Search"
                  >Search</el-button
                >
              </template>
            </el-input>
          </template>
          <template #default="scope">
            <el-button
              size="small"
              @click="ConfigurationEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>

    <el-tab-pane name="website" label="网站配置">
      <el-form label-width="150px" class="configModule">
        <ConfigurationPictureModule Key="website.logo" />
        <ConfigurationPictureModule Key="system.default.productImage" />
        <ConfigurationTextModule Key="website.name" />
        <ConfigurationTextModule Key="website.home.notice" />
        <ConfigurationSwitchModule Key="system.default.CloseTheWebsite" />

        <ConfigurationNumberModule
          Key="system.default.dispatchingStartAmount"
        />
        <ConfigurationTextModule Key="website.url" />
        <ConfigurationTextModule Key="website.app.url" />
      </el-form>
    </el-tab-pane>
 

    <el-tab-pane name="invoice" label="发票配置">
      <el-form label-width="150px" class="configModule">
        <ConfigurationTextModule Key="system.invoice.LicenciaTBAI" />
        <ConfigurationTextModule Key="system.invoice.NIF" /> 
        <ConfigurationTextModule Key="system.invoice.Version" />
        <ConfigurationTextModule Key="system.invoice.Nombre" />
 
      </el-form>
    </el-tab-pane>
    
  </el-tabs>
</navigation>
  <!-- 新增或者编辑 -->
  <el-dialog v-model="ConfigurationVisible" title="Tips" width="60%">
    <el-form label-position="right" label-width="100px">
      <el-form-item label="Key">
        <el-input v-if="IsAdd" v-model="ConfigurationItem.Key"></el-input>
        <el-input
          v-if="!IsAdd"
          disabled
          v-model="ConfigurationItem.Key"
        ></el-input>
      </el-form-item>
      <el-form-item label="名称">
        <el-input v-model="ConfigurationItem.Name"></el-input>
      </el-form-item>
      <el-form-item label="值">
        <el-input
          v-model="ConfigurationItem.Value"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="值">
        <el-select v-model="ConfigurationItem.ConfigureType" size="large">
          <el-option
            v-for="item in ConfigureType"
            :key="item.Value"
            :label="item.Value"
            :value="item.Key"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="ConfigurationVisible = false">关闭</el-button>
        <el-button type="primary" @click="SaveConfigurationVisible()"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script  >
import axios from "../../../commons/AxiosMethod.js"
import { Search } from "@element-plus/icons-vue"
import { ElMessageBox } from "element-plus"

import navigation from "../../../components/Modules/AdminNavigation.vue";
import ConfigurationPictureModule from "../../../components/ConfigurationPictureModule.vue"
import ConfigurationTextModule from "../../../components/ConfigurationTextModule.vue"
import ConfigurationSwitchModule from "../../../components/ConfigurationSwitchModule.vue"
import ConfigurationNumberModule from "../../../components/ConfigurationNumberModule.vue"
import ConfigurationHideModule from "../../../components/ConfigurationHideModule.vue"
import ConfigurationCollectionModule from "../../../components/ConfigurationCollectionModule.vue"

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data () {
    return {
      ConfigurationActiveName: "first", // 默认选项卡
      Configurations: [], // 配置集合
      ConfigurationItem: {}, // 当前编辑配置
      ConfigurationVisible: false,
      SearchConfigValue: "", // 搜索值
      IsAdd: false,
      ConfigureType: [], // 配置类型

      ConfigurationStruct: [], // 值类型数据
      ConfigurationDocument: [], // 文件类型数据
      ConfigurationPicture: [], // 图片类型数据
      ConfigurationSystem: [] // System
    }
  },
  name: "App",
  mounted () {
    this.GetConfigurations()
    this.GetConfigureTypeFormet()
  },
  components: {
    Search,navigation,
    ConfigurationPictureModule,
    ConfigurationTextModule,
    ConfigurationSwitchModule,
    ConfigurationNumberModule,
    ConfigurationHideModule,
    ConfigurationCollectionModule
  },
  methods: {
    // 清除所有商品数据
    DeleteDataBaseAll () {
      ElMessageBox.confirm("确定要删除所有数据?")
        .then(() => {
          axios.apiMethod(
            "/admins/SystemConfiguration/DeleteDataBase",
            "get",
            {},
            (result) => {
              if (result.Code == 200) {
                alert("success")
              } else {
                ElMessageBox({
                  message: result.FullMsg,
                  type: "error"
                })
              }
            }
          )
        })
        .catch(() => {
          // catch error
        })
    },
    CreateDocument () {
      ElMessageBox.confirm("确定要创建新的默认数据吗?")
        .then(() => {
          axios.apiMethod(
            "/admins/SystemConfiguration/CreateDocument",
            "get",
            {},
            (result) => {
              if (result.Code == 200) {
                alert("success")
              } else {
                ElMessageBox({
                  message: result.FullMsg,
                  type: "error"
                })
              }
            }
          )
        })
        .catch(() => {
          // catch error
        })
    },

    DeleteAllCache () {
      ElMessageBox.confirm("确定要删除所有缓存吗?")
        .then(() => {
          axios.apiMethod(
            "/admins/SystemConfiguration/DeleteAll",
            "get",
            {},
            (result) => {
              if (result.Code == 200) {
                alert("删除成功!")
              } else {
                ElMessageBox({
                  message: result.FullMsg,
                  type: "error"
                })
              }
            }
          )
        })
        .catch(() => {
          // catch error
        })
    },

    WriteInDocument () {
      ElMessageBox.confirm("确定要写入默认数据吗?")
        .then(() => {
          axios.apiMethod(
            "/admins/SystemConfiguration/WriteInDocument",
            "get",
            {},
            (result) => {
              if (result.Code == 200) {
                alert("success")
              } else {
                ElMessageBox({
                  message: result.FullMsg,
                  type: "error"
                })
              }
            }
          )
        })
        .catch(() => {
          // catch error
        })
    },
    GetConfigureTypeFormet () {
      axios.apiMethod(
        "/admins/SystemConfiguration/GetConfigureTypeFormet",
        "get",
        {},
        (result) => {
          if (result.Code == 200) {
            this.ConfigureType = result.Data
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    // 保存
    SaveConfigurationVisible () {
      axios.apiMethod(
        "/admins/SystemConfiguration/AddOrUpdateConfigurations",
        "post",
        this.ConfigurationItem,
        (result) => {
          if (result.Code == 200) {
            this.GetConfigurations()
            this.ConfigurationVisible = false
            ElMessageBox({
              title: "提示",
              message: "操作成功"
            })
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    // 更新主机配置i
    UpdateWebHostConfiguration () {
      ElMessageBox.confirm("确定要更新主机配置吗?")
        .then(() => {
          axios.apiMethod(
            "/admins/SystemConfiguration/UpdateWebHostConfiguration",
            "get",
            {},
            (result) => {
              if (result.Code == 200) {
                this.GetConfigurations()
                this.ConfigurationVisible = false
                ElMessageBox({
                  title: "提示",
                  message: "操作成功"
                })
              } else {
                ElMessageBox({
                  message: result.FullMsg,
                  type: "error"
                })
              }
            }
          )
        })
        .catch(() => {
          // catch error
        })
    },
    ConfigurationAdd () {
      // 新增
      this.ConfigurationItem = {}
      this.ConfigurationItem.ConfigureType = 0
      this.IsAdd = true
      this.ConfigurationVisible = true
    },
    ConfigurationEdit (index, item) {
      // 编辑
      this.IsAdd = false
      this.ConfigurationItem = item
      this.ConfigurationVisible = true
    },

    // 查询所有配置
    GetConfigurations () {
      const param = {
        where: {
          Search: this.SearchConfigValue
        }
      }
      axios.apiMethod(
        "/admins/SystemConfiguration/GetConfigurations",
        "post",
        param,
        (result) => {
          if (result.Code == 200) {
            this.Configurations = result.Data.Datas

            this.Configurations.forEach((e) => {
              if (e.ConfigureType == 400) {
                this.ConfigurationSystem.push(e)
              } else if (e.ConfigureType == 200) {
                this.ConfigurationPicture.push(e)
              } else if (e.ConfigureType == 300) {
                this.ConfigurationDocument.push(e)
              } else if (e.ConfigureType == 100) {
                this.ConfigurationStruct.push(e)
              }
            })
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    }
  }
}
</script>

<style lang="less" scoped>
.configModule {
  width: 80%;
  margin: 0 auto;
}
</style>
