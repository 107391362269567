<template>
  <!-- 配置文本 -->
  <el-form-item :label="ConfigModel.Name">
    <el-col :span="11">
      <el-input-number v-model="ConfigModel.Number" />
    </el-col>
    <el-col :span="2"> </el-col>
    <el-col :span="11">
      <el-button @click="SaveConfig"> 保存 </el-button>
    </el-col>
  </el-form-item>
</template>
<script>
import { ElMessage } from "element-plus"
import { Plus } from "@element-plus/icons-vue"
import axios from "../commons/AxiosMethod.js"
export default {
  name: "header",
  data () {
    return {
      ImageValue: "",
      UploadImageUrl: "",

      ConfigModel: {
        Number: 0
      }
    }
  },
  props: {
    Key: ""
    // Max: 9999999,
    // Min: -999999,
  },
  components: {
    Plus
  },
  mounted () {
    this.GetConfig(this.Key)
    this.UploadImageUrl = axios.GetUrl() + "/api/Upload/ImageFile"
    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header")
    }

    // 这个是页面创建的时候，就执行相关的方法
  },
  methods: {
    GetConfig (key) {
      axios.apiMethod(
        "/admins/SystemConfiguration/GetConfigurationsByKey",
        "get",
        { Key: key },
        (result) => {
          if (result.Code == 200) {
            this.ConfigModel = result.Data
            this.ConfigModel.Number = parseInt(this.ConfigModel.Value)
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    SaveConfig () {
      this.ConfigModel.Value = this.ConfigModel.Number
      axios.apiMethod(
        "/admins/SystemConfiguration/AddOrUpdateConfigurations",
        "post",
        this.ConfigModel,
        (result) => {
          if (result.Code == 200) {
            ElMessage({
              message: "修改成功",
              type: "success"
            })
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    }
  }
}
</script>
