<template>
  <!-- 配置文本 -->
  <el-form-item label="首页配置">
    <el-col :span="11">
      <el-checkbox-group v-model="HomeTypes" @change="ChangeHomeTypes">
        <el-checkbox label="FixedTaoCan" border> 固定套餐</el-checkbox>
        <el-checkbox label="TaoCan" border> 显示套餐</el-checkbox>
        <el-checkbox label="Product" border>显示菜品 </el-checkbox>
        <el-checkbox label="Default" border> 全部</el-checkbox>
      </el-checkbox-group>
    </el-col>
    <el-col :span="2"> - </el-col>
    <el-col :span="11">
      <el-button type="warning" @click="SaveConfig">保存</el-button>
    </el-col>
  </el-form-item>
  <el-form-item label="指定套餐" v-if="HomeTypes.indexOf('FixedTaoCan') > -1">
    <el-col :span="11">
      <el-select v-model="CollectionOpt" class="m-2" placeholder="Select" size="large">
        <el-option v-for="item in CollectionList" :key="item.Id" :label="item.Name" :value="item.Id" />
      </el-select>
    </el-col>
    <el-col :span="2"> - </el-col>
  </el-form-item>

  <ConfigurationSwitchModule Key="system.collection.single" />
  <el-divider content-position="left"> 下单配置 （请在平板服务中配置，此处暂时只做查看）</el-divider>
  <el-form-item label="不限制次数">
    <el-input type="text" v-model="OrderRestrictOpt.NotLimitQty" /> </el-form-item>
  <el-form-item label="每次数量">
    <el-input type="text" v-model="OrderRestrictOpt.FoodLimitQty" /> </el-form-item>
  <el-form-item label="间隔时间">
    <el-input type="text" v-model="OrderRestrictOpt.FoodLimitTime" /> </el-form-item>
 

  <el-divider content-position="left"> 不限制类型 （请在平板服务中配置，此处暂时只做查看）</el-divider>

  <el-form-item label="其他">
    <el-switch v-model="OrderRestrictOpt.rest" class="ml-2" active-color="#13ce66" inactive-color="#ff4949" />
  </el-form-item>


  <el-form-item label="甜点">
    <el-switch v-model="OrderRestrictOpt.mousse" class="ml-2" active-color="#13ce66" inactive-color="#ff4949" />
  </el-form-item>


  <el-form-item label="食品">
    <el-switch v-model="OrderRestrictOpt.food" class="ml-2" active-color="#13ce66" inactive-color="#ff4949" />
  </el-form-item>


  <el-form-item label="饮料">
    <el-switch v-model="OrderRestrictOpt.beverage" class="ml-2" active-color="#13ce66" inactive-color="#ff4949" />
  </el-form-item>

  <el-form-item label="时间限制">
    <el-switch v-model="OrderRestrictOpt.timeRestrict" class="ml-2" active-color="#13ce66" inactive-color="#ff4949" />
  </el-form-item>

  
</template>
<script>
import { ElMessage } from "element-plus"
import { Plus } from "@element-plus/icons-vue"
import axios from "../commons/AxiosMethod.js"
import ConfigurationSwitchModule from "../components/ConfigurationSwitchModule.vue"
export default {
  name: "header",
  data() {
    return {
      ImageValue: "",
      UploadImageUrl: "",

      ConfigModel: {
        SwitchValue: false
      },
      HomeTypes: [],
      CollectionList: "",
      CollectionOpt: "",
      OrderRestrictOpt: {},//套餐配置类型
    }
  },
  props: {
    Key: ""
  },
  components: {
    Plus,
    ConfigurationSwitchModule
  },
  mounted() {
    this.GetConfig(this.Key)
    this.GetCollectionList()
    this.GetOrderRestrict()

    // 这个是页面创建的时候，就执行相关的方法
  },
  methods: {

    //获取套餐配置
    GetOrderRestrict() {
      axios.apiMethod(
        "/admins/SystemConfiguration/GetOrderRestrict",
        "get",
        {},
        (result) => {
          this.OrderRestrictOpt = result.Data
        }
      )


    },
    ChangeHomeTypes() {
      if (this.HomeTypes.indexOf("FixedTaoCan") > -1) {
        this.HomeTypes = ["FixedTaoCan"]
      }
    },
    // 获取套餐列表
    GetCollectionList() {
      axios.apiMethod(
        "/shoppings/Collection/GetCollectionList",
        "get",
        {},
        (result) => {
          this.CollectionList = result.Data
        }
      )
    },
    GetConfig(key) {
      axios.apiMethod(
        "/admins/SystemConfiguration/GetConfigurationsByKey",
        "get",
        { Key: key },
        (result) => {
          if (result.Code == 200) {
            this.ConfigModel = result.Data
            this.HomeTypes = []
            try {
              if (
                this.ConfigModel.Value == null ||
                this.ConfigModel.Value.length < 5
              ) {
                this.CollectionOpt = ""
                this.HomeTypes = []
                return
              }
              this.HomeTypes = JSON.parse(this.ConfigModel.Value).HomeTypes
              this.CollectionOpt = JSON.parse(
                this.ConfigModel.Value
              ).CollectionOpt

              if (
                !!this.HomeTypes ||
                this.HomeTypes == undefined ||
                this.HomeTypes == "undefined"
              ) {
                this.HomeTypes = []
              }
            } catch (e) {
              this.HomeTypes = []
            }
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    SaveConfig() {
      this.ConfigModel.Value = JSON.stringify({
        HomeTypes: this.HomeTypes,
        CollectionOpt: this.CollectionOpt
      })
      axios.apiMethod(
        "/admins/SystemConfiguration/AddOrUpdateConfigurations",
        "post",
        this.ConfigModel,
        (result) => {
          if (result.Code == 200) {
            ElMessage({
              message: "修改成功",
              type: "success"
            })
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    }
  }
}
</script>
