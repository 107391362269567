<template>
  <!-- 配置文本 -->
  <el-form-item :label="ConfigModel.Name">
    <el-col :span="11">
      <el-input v-model="ConfigModel.Value"></el-input>
    </el-col>
    <el-col :span="2" class="text-center">
      <span class="text-gray-500">-</span>
    </el-col>
    <el-col :span="11">
      <el-button @click="SaveConfig"> 保存 </el-button>
    </el-col>
  </el-form-item>
</template>
<script>
import { ElMessage } from "element-plus"
import { Plus } from "@element-plus/icons-vue"
import axios from "../../src/commons/AxiosMethod.js"

export default {
  name: "header",
  data () {
    return {
      ImageValue: "",
      UploadImageUrl: "",

      ConfigModel: {}
    }
  },
  props: {
    Key: ""
  },
  components: {
    Plus

  },
  mounted () {
    this.GetConfig(this.Key)

    // 这个是页面创建的时候，就执行相关的方法
  },
  methods: {
    GetConfig (key) {
      axios.apiMethod(
        "/admins/SystemConfiguration/GetConfigurationsByKey",
        "get",
        { Key: key },
        (result) => {
          if (result.Code == 200) {
            this.ConfigModel = result.Data
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    SaveConfig () {
      axios.apiMethod(
        "/admins/SystemConfiguration/AddOrUpdateConfigurations",
        "post",
        this.ConfigModel,
        (result) => {
          if (result.Code == 200) {
            ElMessage({
              message: "修改成功",
              type: "success"
            })
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    }
  }
}
</script>
